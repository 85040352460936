<template>
  <div
      class="form-builder__input"
      :class="schema.styleClasses || ''"
  >
    <q-input
        ref="input"
        :standout="!!schema.standout"
        :borderless="schema.borderless || undefined"
        :model-value="date"
        @update:model-value="handleInput"
        :disable="!!schema.disabled"
        :required="!!schema.required"
        :label="`${schema.label} ${schema.required ? '*' : ''}`"
        :hide-bottom-space="!!schema.hint"
        :dense="!!schema.dense"
        :autocomplete="schema.autocomplete || ''"
        class="q-custom-date q-field--float"
        :class="className"
        type="datetime-local"
        @click="openDatePopup"
    >
      <template v-slot:prepend>
        <q-icon name="event" class="cursor-pointer">
          <q-popup-proxy
              ref="qDateProxy"
              transition-show="scale"
              transition-hide="scale"
              @hide="updateDate"
          >
            <div class="row">
              <div class="col">
                <q-time
                    v-model="proxyTime"
                    mask="HH:mm"
                    :format24h="true"
                />
              </div>
              <div class="col">
                <q-date
                    v-model="proxyDate"
                    first-day-of-week="1"
                    mask="YYYY-MM-DD"
                />
              </div>

            </div>

          </q-popup-proxy>
        </q-icon>

        <q-icon v-if="schema.value && schema.hasResetBtn" name="close" @click="reset" class="cursor-pointer" />
      </template>
    </q-input>
  </div>
</template>

<script>
import { date } from 'quasar'

export default {
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      proxyDate: '',
      proxyTime: '00:00'
    }
  },
  computed: {
    className () {
      return this.schema.value
          ? ''
          : 'q-field--no-placeholder'
    },
    date () {
      const value = (typeof this.schema.get === 'function' && this.schema.get(this.schema.value)) || this.schema.value
      return this.createDate(value)
    }
  },
  watch: {
    proxyDate () {
      if (this.$refs.qDateProxy) {
        this.$refs.qDateProxy.hide()
      }
    }
  },
  mounted () {
    const value = (typeof this.schema.get === 'function' && this.schema.get(this.schema.value)) || this.schema.value
    this.proxyDate = this.createDate(value)
  },
  methods: {
    openDatePopup() {
      this.$refs.qDateProxy.show();
    },
    reset () {
      this.handleInput('')
    },
    createDate (value) {
      if (!value) {
        return ''
      }

      const newDate = new Date(value)
      return date.formatDate(newDate, 'YYYY-MM-DDTHH:mm')
    },
    updateDate () {
      this.handleInput(`${this.proxyDate}T${this.proxyTime}`)

      this.$nextTick(() => {
        const value = (typeof this.schema.get === 'function' && this.schema.get(this.schema.value)) || this.schema.value
        const datetime = this.createDate(value)
        this.$refs.input.getNativeElement().value = datetime
      })
    },
    handleInput (e) {
      if (this.schema.max) {
        const max = new Date(this.schema.max).getTime()
        const current = new Date(e).getTime()

        if (current > max) {
          return typeof this.schema.onError === 'function' && this.schema.onError({ type: 'max' })
        }
      }

      typeof this.schema.onChange === 'function' && this.schema.onChange(e)
    },
    handleFocus (e) {
      if (this.schema.onFocus) {
        this.schema.onFocus(e)
      }
    },
    handleFocusOut (e) {
      if (this.schema.onFocusOut) {
        this.schema.onFocusOut(e)
      }
    }
  }
}
</script>

<style>
.q-custom-date input::-webkit-inner-spin-button,
.q-custom-date input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.q-field--no-placeholder input[type="datetime-local"]::before {
  content: '';
  width: 100%;
}
</style>
